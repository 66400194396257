import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import SEO from "../components/elements/SEO";
import Layout from "../components/layout/Layout";
import SectionList from "../components/sections/SectionList";

const query = graphql`
  query {
    strapiCareerPage {
      sections {
        ... on StrapiComponentBlocksSeparator {
          __typename
          id
          color
          background
        }
        ... on StrapiComponentBlocksCardGrid {
          id
        }
        ... on StrapiComponentBlocksTwoColumnCallToAction {
          id
        }
        ... on StrapiComponentBlocksTwoColumnHero {
          id
        }
        ... on StrapiComponentBlocksTextBlock {
          __typename
          id
          hero
          blockInfo {
            paddingTop
            paddingBottom
            description
            background
            title
            anchor
          }
        }
        ... on StrapiComponentBlocksImageList {
          __typename
          id
          blockInfo {
            id
            paddingTop
            paddingBottom
            title
            description
            background
            anchor
          }
          images {
            file {
              publicURL
            }
          }
        }
        ... on StrapiComponentBlocksCallToActionCard {
          __typename
          id
          action {
            url
            label
          }
          blockInfo {
            anchor
            background
            description
            paddingBottom
            paddingTop
            title
          }
        }
        ... on StrapiComponentBlocksImageGrid {
          __typename
          id
          blockInfo {
            anchor
            background
            description
            paddingBottom
            paddingTop
            title
          }
          images {
            file {
              publicURL
            }
          }
        }
        ... on StrapiComponentBlocksCardGrid {
          __typename
          id
          blockInfo {
            anchor
            paddingTop
            paddingBottom
            description
            background
            title
          }
          cards {
            title
            description
            icon {
              file {
                publicURL
              }
            }
          }
        }
        ... on StrapiComponentBlocksExpandableCardGrid {
          __typename
          id
          cards {
            title
            description
            banner {
              file {
                publicURL
              }
            }
          }
          blockInfo {
            background
            description
            anchor
            paddingBottom
            paddingTop
            title
          }
          buttonLabel
          formTitle
          postURL
          form {
            errorMessage
            label
            placeholder
            options
            type
            required
            requestId
          }
        }
        ... on StrapiComponentBlocksImageGrid {
          __typename
          id
          images {
            file {
              publicURL
            }
          }
          columns
          blockInfo {
            title
            paddingTop
            paddingBottom
            description
            background
            anchor
          }
        }
      }
      pageInfo {
        seoTitle
      }
    }
  }
`;

function Careers() {
  const { strapiCareerPage } = useStaticQuery(query);
  return (
    <Layout>
      <SEO title={strapiCareerPage.pageInfo.seoTitle} />
      <SectionList sections={strapiCareerPage.sections} />
    </Layout>
  );
}

export default Careers;
